@import 'mixins';
@import 'variables';

.Admin-Landing-Container {
  text-align: center;
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  //padding: 4rem;

  .Container-Header {
    margin-bottom: 2rem;
  }
}

.font-larger {
  font-size: larger;
}