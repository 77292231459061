@import 'mixins';

.ant-tag {
  .anticon-close {
    svg {
      vertical-align: unset;
    }
  }
}

.Crud-DataTable-Row {
  background-color: #fafafa !important;
}

.Crud-DataTable-Row-Highlight {
  background-color: white;
  font-weight: 800;
  border: 3px;
}

.ant-table-tbody {
  .Crud-DataTable-Row-Highlight:hover td {
    background-color: #fdfdfd !important;
  }
}