.Attachment-Card-Title {
    color: $primaryTextColor !important;
    font-weight: 800!important;
}

.Appointment-Detail-Title {
    color: $primaryTextColor !important;
    font-weight: 700;
}

.Appointment-Detail-Text {
    color: $secondaryLightTextColor !important;
    font-weight: 700;
}