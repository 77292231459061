@import './variables.scss';
@import './_mixins.scss';

.fivef-navbar {
    background-color: #fff!important;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.18);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.18);
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.18);

    .fivef-navbar-brand {
        min-width: 245px;
        font-weight: 800;
    }
}

.fivef-user-dropdown {
    font-weight: 700;

    .dropdown-toggle {
        margin-top: 5px!important;
    }

    .dropdown-menu {
        border: none;
        border-radius: 5px;
    }

    .dropdown-item {
        font-size: 16px;
    }
}

.btn-toggle-mb-sidebar {
    height: 100%!important;
    position: absolute!important;
    right: 0!important;
    border: 0!important;
    border-radius: 0!important;
}

#pageWrapper {
    @include xs {
        margin-left: 0;
    }

    @include sm {
        margin-left: 0;
    }

    @include md {
        margin-left: 0 !important;
    }

    // Large devices
    @include lg {
        margin-left: 250px;
    }

    // Extra large devices
    @include xl {
        margin-left: 250px;
    }  
}

.sidebar-hidden {
    @include xs {
        margin-left: 0!important;
    }

    @include sm {
        margin-left: 0!important;
    }

    @include md {
        margin-left: 0!important;
    }

    // Large devices
    @include lg {
        margin-left: 0!important;
    }

    // Extra large devices
    @include xl {
        margin-left: 0!important;
    }
} 

.sidebar {
    background-color: #fff;
    transition: .2s;

    @include xs {
        position: absolute;
        left: -250px;
    }

    @include sm {
        position: absolute;
        left: -250px;
    }

    @include md {
        position: absolute;
        left: -250px;
    }

    @include lg {
        left: -250px;
    }

    @include xl {
        left: -250px;
    }
    
    
    &.active {
        @include xs {
            position: absolute;
            z-index: 99;
            width: 100vw;
            left: 0;
        }

        @include sm {
            position: absolute;
            z-index: 99;
            width: 100vw;
            left: 0;
        }

        @include md {
            position: absolute;
            z-index: 99;
            width: 250px;
            left: 0;
        }

        // Large devices
        @include lg {
            z-index: 99;
            width: 250px;
            left: 0;
        }

        // Extra large devices
        @include xl {
            z-index: 99;
            width: 250px;
            left: 0;
        }
    }

    ul {
        li {
            border: none!important;
            margin: 5px 10px!important;

            &.Sidebar-Sign-In {
                margin-top: 20px !important;
                margin-left: 25px !important;
            }

            a {
                padding: 12px 15px;
                color: #0170FE;

                &:hover {
                    background-color: #F3F8FF;
                    border-radius: 7px!important;
                }

                &.active {
                    background-color: #0170FE!important;
                    border-radius: 7px!important;
                    color: #fff!important;
                    font-weight: 800!important;
                }
            }
        }
    }
}

#responsive-navbar-nav {
    @include xs {
        display: none !important;
    }
    
    @include sm {
        display: none !important;
    }

    @include md {
        display: none !important;
    }

    @include lg {
        display: flex !important;
    }

    @include xl {
        display: flex !important;
    }
}

.ant-badge {
    color: inherit !important;
}